import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

import { HttpWithAuthService } from './../project-common/services/http-interceptor.service';
import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ProductLevel3 } from '../project-common/models/product-level-3.model';
import { Storefront } from '../project-common/properties/constants';
import { ServiceResponse } from '../project-common/models/network-request.model';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { ApiResponseCommon, ApiResponseCommonV1 } from '@kalgudi/types';

declare var ga: any;
@Injectable({
  providedIn: 'root'
})
export class StorefrontService {

  /**
   * Contains list of saleable products;
   */
  productList$: BehaviorSubject<ProductLevel3[]>;

  /**
   * Contains `true` only if any of saleable product is available for sale
   */
  isSaleOpen$: BehaviorSubject<boolean>;

  private homeCategories$: BehaviorSubject<any>;

  private languageId: string;

  categories$: Observable<{ id: string, value: string }[]> = of(categories);

  constructor(
    private httpWithAuth: HttpWithAuthService,
    private http: HttpClient,
    private util: KalgudiUtilityService,
  ) {
    this.isSaleOpen$ = new BehaviorSubject<boolean>(JSON.parse(localStorage['isSaleOpen'] || 'true'));
  }

  public getBasketProducts(l2Id) {
    const url = `${environment.restBaseUrlV2}/listing/products/l2/${l2Id}/basketItems?storeType=BIZ_STORE&languageId=1`;

    return this.http.get<any>(url).pipe(map(res => res.data));
  }

  public getProductList(request: string[], language?: string): Observable<any> {
    let categoriesCount = request.length;
    return new Observable(observer => {
      const url = `${environment.baseUrl}/data/storeProducts/biz/categories/${environment.sellerProfile}_`;
      const sufix = this.getSuffix(language);
      request.forEach(category => {
        let temp = '';
        if (request[0].includes('PRFREG')) {
          temp = '_STORE_PRODUCT_CARDS';
        }
        this.http.get(url + category + sufix + temp).subscribe(response => {
          --categoriesCount;
          try {
            const data: any = response;
            data.listOfLevel2Cards = this.suffleProducts(data.listOfLevel2Cards);
            // debugger
            observer.next(data);
          } catch (e) {
            ga('send', 'exception', {
              'exDescription': 'Unable to parse: ' + e.url,
              'exFatal': false
            });
            /* throw new Error('EXCEPTION: FILE_NOT_FOUND\n ' + e); */
          }

          if (!categoriesCount) {
            observer.complete();
          }
        }, error => {
          --categoriesCount;
          ga('send', 'exception', {
            'exDescription': 'File not found: ' + error.url,
            'exFatal': false
          });
          // observer.error(error);
          if (!categoriesCount) {
            observer.complete();
          }
          /* throw new Error('No data found'); */
        });
      });
    });
  }

  public getProductDetails(postId: string): Observable<any> {
    let url = environment.baseUrl;
    url += '/rest/v1/business/requirements/quote/' + postId + '_master/thread/' + postId + '_master';

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }));
  }

  public getOffers() {
    const url = `${environment.baseUrl}/rest/v1/ecom/orders/sankrantiOffers`;
    return this.http.get(url).pipe(map(data => {
      let response: any = data;
      response = JSON.parse(response.data);
      // response.offers[0].from = new Date();
      return response;
    }));
  }

  public viewTrace(traceId: string): Observable<any> {
    let url = environment.baseUrl;
    url += '/rest/v1/trace/viewTrace/' + traceId;
    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }));
  }

  public isTraceExistsForAmp(postId: string): Observable<any> {
    let url = environment.baseUrl;
    url += '/rest/v1/trace/amp/status?postId=' + postId;
    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }));
  }

  public getFullViewData(productId: string, language?: string, isPreview?: boolean, storeName?:string, bulkOrder?:any): Observable<any> {
    let url = (isPreview || bulkOrder) ? environment.bucketUrl : environment.baseUrl;
    const sufix = this.getSuffix(language);    
    if(storeName==='SIKKIM') {
      url += `/data/storeProducts/sikkim/${productId}${sufix}`;
    } else {
      url += `/data/storeProducts/biz/${productId}${sufix}`;
    }    
    return this.http.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No product found');
    }));
  }

  public getProductReviewMetaData(productId: string, loadMore?): Observable<any> {
    let url = environment.baseUrl;
    url += `/data/storeProducts/biz/reviewAndRating/${productId}_${loadMore.start}_${loadMore.end}`;
    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No reviews found');
    }));
  }

  /**
   * We get the data related to reviews and ratings from s3.
   * @param productId productLevel2Id 
   */
  public getProductReviewRatingsMetaData(productId: string): Observable<any> {
    let url = environment.baseUrl;
    url += `/data/reviewAndRating/biz/${productId}_METADATA`;
    return this.httpWithAuth.get(url).pipe(map(data => {
      return data as any;
    }, error => {
      throw new Error('No reviews found');
    }));
  }


  public getSellerCatalogueList(profileKey: string, storeName?:string, isML1?: boolean, isML2?: boolean, productlevel2Id?: string): Observable<any> {

    let url = environment.baseUrl;

    url += isML1 || isML2 ? `/data/similar-products/${isML2 ? 'v1/' + productlevel2Id : productlevel2Id}_SAME_SELLER` :
      `/data/storeProducts/biz/categories/${profileKey}_BIZ_CATALOG`;

    if(storeName === 'SIKKIM') {
      url = url.replace('biz', 'sikkim').replace('_BIZ_CATALOG', '_SIKKIM_CATALOG');
    }

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No products found');
    }));
  }

  getCategoriesFromCategoryIds(categoryIds: string[]) {
    return combineLatest(categoryIds.map(categoryId => {
      const url = `${environment.baseUrl}/data/storeProducts/unified_store/home_page_${categoryId}`;
      return this.http.get(url).pipe(catchError(e => of(null)));
    })).pipe(
      map(
        categories => categories
          .filter(c => !!c)
          .map(category => {
            let productsWithPrice = [];
            let productsWithOutPrice = [];
            category.listOfLevel2Cards.forEach(p => +p.pricePerSKU >= 1 ? productsWithPrice.push(p) : productsWithOutPrice.push(p));
  
            productsWithPrice = productsWithPrice.sort((p1, p2) => (+p1.pricePerSKU - +p2.pricePerSKU)); //sorting by price
            productsWithOutPrice = productsWithOutPrice.sort((p1, p2) => (new Date(p2.LUT).getTime() - new Date(p1.LUT).getTime())); //sorting by date
  
            category.listOfLevel2Cards = [...productsWithPrice, ...productsWithOutPrice];

            category.listOfLevel2Cards.map(p => !p.productCategory ? p.productCategory = category.id : p.productCategory);

            return category
          })
          // .reduce((a, c) => { a[c.id] = c; return a; }, {})
      ));
  }

  public getHomeCategories(langCode: string) {
    const languageId = this.getSuffix(langCode).replace('_', '') || '1';
    if (this.homeCategories$ && languageId === this.languageId) {
      return this.homeCategories$.asObservable();
    } else {
      this.languageId = languageId;
      this.homeCategories$ = new BehaviorSubject<any>(null);
      const url = `${environment.baseUrl}/data/storeProducts/unified_store/home_page`;
      /* const params = {
        store: 'KALGUDI',
        languageId: languageId || '1'
      } */
      this.http.get(url/* , { params } */).subscribe((response: ServiceResponse) => {
        let categoryList = [];
        Object.keys(response).forEach(key => {
          const category: any = response[key];

          let productsWithPrice = [];
          let productsWithOutPrice = [];
          category.listOfLevel2Cards.forEach(p => +p.pricePerSKU >= 1 ? productsWithPrice.push(p) : productsWithOutPrice.push(p));

          productsWithPrice = productsWithPrice.sort((p1, p2) => (+p1.pricePerSKU - +p2.pricePerSKU)); //sorting by price
          productsWithOutPrice = productsWithOutPrice.sort((p1, p2) => (new Date(p2.LUT).getTime() - new Date(p1.LUT).getTime())); //sorting by date

          category.listOfLevel2Cards = [...productsWithPrice, ...productsWithOutPrice];


          // category.listOfLevel2Cards = category.listOfLevel2Cards.sort((p1, p2) => (+p1.pricePerSKU - +p2.pricePerSKU));


          category.listOfLevel2Cards.map(p => !p.productCategory ? p.productCategory = key : p.productCategory);



          categoryList.push(category);
        });
        categoryList = categoryList.sort((a, b) => {
          return b.listOfLevel2Cards.length - a.listOfLevel2Cards.length;
        });
        this.homeCategories$.next(categoryList);
        this.categories$ = of(categoryList.map(c => {
          return {
            id: c.id,
            value: c.value
          }
        }));
      });
      return this.homeCategories$.asObservable();
    }
    /* return new Observable(observer => {
      this.getSellerCatalogueList(environment.sellerProfile).subscribe((res: any) => {
        let items: any[] = res.listOfLevel2Cards
          .filter(item => {
            return new Date(item.createdTS) > new Date(2020, 4, 18)
          });
        const updatedList = [];
        items.forEach(item => {
          this.getFullViewData(item.productId_level2).subscribe(level2Data => {
            updatedList.push(this.prepareCardObject(level2Data, item.productId_level3));
            res.listOfLevel2Cards = updatedList;
            observer.next(res);
            if (updatedList.length === items.length) {
              observer.complete();
            }
          });
        })
      })
    }); */

  }


  getCardObject(level2Id, level3Id) {
    return this.getFullViewData(level2Id)
      .pipe(map(level2Data => this.prepareCardObject(level2Data, level3Id)))
  }

  private prepareCardObject(level2Data: any, l3Id: string) {
    const level3: ProductLevel3 = level2Data.level3ProductsList.find(i => i.productLevel3Id === l3Id);
    const seller = level2Data.resellerDetails || level2Data.manufacturerDetails;

    const { name: teluguName = "" } = level2Data.aka.find(({ language }) => language.toLowerCase() === 'telugu') || {}

    let cardObj: any = {
      LUT: level3.LUT,
      SKUSmartElements: level3.smartElements,
      createdTS: level3.LUT, // level3.createdTS, There's a mismatch in createdTS for product card and real product level 3 
      approxDeliveryTime: level3.approxDeliveryTime,
      description: level2Data.description,
      isBulkOrder: level3.isBulkOrder,
      level2published: level2Data.isPublished,
      level3published: level3.isPublished,
      mrp: level3.mrp,
      pricePerSKU: level3.pricePerUnit,
      productId_level2: level3.productLevel2Id,
      productId_level3: level3.productLevel3Id,
      productName_level2: level3.productLevel2Title,
      productName_level3: level3.productLevel3Title,
      productPicURL_level3: level3.attachments.attachments[0].url,
      selectedSKU: level3.selectedSKU,
      sellerId: seller.profileKey,
      sellerLocation: seller.location,
      sellerName: seller.firstName,
      category: level2Data.baseCategory.value,


      // Extra properties other than card object
      freeShippingEligible: level3.freeShippingEligible,
      shipping: level3.shipping,
      teluguName,
      baseProductName: level2Data.baseProduct.productName
    }
    if (level2Data.reviewsAndRatingsTo) {
      cardObj.reviewsAndRatingsTo = level2Data.reviewsAndRatingsTo;
    }
    return cardObj;
  }

  public getCategorySellers(category: string, offset: number, limit: number): Observable<any> {

    let url = `${environment.baseUrl}/rest/v1/store/biz/productdetails?action=unique&require=sellerdetails&match=${category}&offset=${offset}&limit=${limit}`;

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No reviews found');
    }));
  }

  public getSimilarProductsFromOtherSeller(productName: string, profileKey: string, isML1?: boolean, isML2?: boolean, productlevel2Id?: string): Observable<any> {
    let url = environment.baseUrl;
    url += isML1 || isML2 ? `/data/similar-products/${isML2 ? 'v1/' + productlevel2Id : productlevel2Id}_DIFFERENT_SELLER` :
      `/rest/v1/store/biz/productsearch?keyword=${productName}&offset=0&limit=10&filter=excludeseller:${profileKey}`;

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No reviews found');
    }));
  }

  public suffleProducts(productList): any[] {
    let currentIndex = productList.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = productList[currentIndex];
      productList[currentIndex] = productList[randomIndex];
      productList[randomIndex] = temporaryValue;
    }

    return productList;
  }

  public getSuffix(language: string): string {
    switch (language) {
      case 'en':
        language = '';
        break;
      case 'hi':
        language = '2';
        break;
      case 'te':
        language = '6';
        break;

      default:
        language = '';
        break;
    }
    return language ? '_' + language : '';
    /* [{ languageId: 1, languageName: "English", displayName: "English" },
        { languageId: 2, languageName: "Hindi", displayName: "हिंदी" },
        { languageId: 3, languageName: "Kannada", displayName: "ಕನ್ನಡ" },
        { languageId: 4, languageName: "Marathi", displayName: "मराठी" },
        { languageId: 5, languageName: "Tamil", displayName: "தமிழ்" },
        { languageId: 6, languageName: "Telugu", displayName: "తెలుగు" },
        ]; */
  }

  /*
   * Service To get the Source of procurement (Transactions Data)
   * @param {string} type Operation Type
   * @param {number} limit Limit of data to fetch
   * @param {number} offset Offset of data to fetch
   */
  public getSHGProcurementDetails(shgBusinessKey: string, offset: string, limit: string): Observable<any> {

    let url = environment.baseUrl + '/rest/v1/transactions/sellerPurchaseTransactions' + '?sellerBusinessKey=' + shgBusinessKey + '&limit=' + limit + '&offset=' + offset;

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No Procurement Data Found');
    }));
  }

  public getSellerRatings(profileKey: string): Observable<any> {
    const url = `${environment.baseUrl}/data/profiles/ratingsAndReviews/${profileKey}_METADATA`;

    return this.httpWithAuth.get(url).pipe(map(data => {
      return data;
    }, error => {
      throw new Error('No reviews found');
    }));
  }

  /* public getCategories(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/data/storeProducts/biz/categories/store-product-categories.json`).pipe(map(data => {
      return data;
    }));
  } */

  public getSmartElementLabel(smartElement: string) {
    const availableSku = {
      color: 'Color',
      diameter: 'Diameter',
      dimensions: 'Dimension',
      setCount: 'Count of',
      shape: 'Shape',
      sizeByName: 'Size',
      sizeByNumber: 'Size',
      smartLabel: '',
      weight: 'Weight'
    }
    return availableSku[smartElement];
  }

  getPrivacyTerm(request) {
    return this.http.get(`assets/templates/${request}`, { responseType: 'text' });
  }

  public saveFarmerDetails(payload: string): Observable<any> {
    let url = environment.restBaseUrlV2;
    url += '/listing/outputstore/users';

    return this.http.post(url, payload);
  }

  preBook(request) {
    const url = `${environment.nodeServer}/rest/v2/store/OUTPUTS/order/prebook`;
    return this.http.post<any>(url, request);
  }


  /**
   * List of pincodes for which all products can be delivered including fruits & vegetables
   */
  deliverablePinCodes$ = this.http
    .get<string[]>(`${environment.baseUrl}/data/storeProducts/biz/${Storefront.APP_NAME}`)
    .pipe(shareReplay(),
      catchError(err => {
        return []
      }));

  /* public getSaleableProducts(productIds: { l2Id: string, l3Id: string }[]): Observable<ProductLevel3[]> {

    if (!this.productList$) {
      this.productList$ = new BehaviorSubject<ProductLevel3[]>(JSON.parse(localStorage['saleableItems'] || '[]'));
      const products = [];
      productIds.forEach(item => {
        this.getFullViewData(item.l2Id).subscribe(data => {
          const level3 = data.level3ProductsList.find(p => p.productLevel3Id === item.l3Id);
          // level3.productLevel2Id = item.l2Id;
          products.push(level3);
          if (products.length === productIds.length) {
            localStorage['saleableItems'] = JSON.stringify(products);
            this.productList$.next(products);
            const isSaleOpen = products.filter(i => i.isPublished).length > 0;
            localStorage['isSaleOpen'] = isSaleOpen;
            this.isSaleOpen$.next(isSaleOpen);
          }
        });
      });
    }
    return this.productList$.asObservable();
  } */

  /**
   * To check if products available in store is still open for sale
   */
  public isSaleOpen(): Observable<boolean> {
    return this.isSaleOpen$.asObservable();
  }

  /**
   * To get product reviews
   */
  getAllReview(offset: number = 0, limit: number = 20): Observable<any> {
    const url = `${environment.nodeServer}/v2/store/${Storefront.APP_NAME}/review/allReviews?offset=${offset}&limit=${limit}`;
    return this.http.get<any>(url)
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  /**
   * Api to get reviews and comments
   */
  getReviewsComments(level2Id: string): Observable<any> {
    let url = `${environment.baseUrl}/data/reviewAndRating/biz/${level2Id}_METADATA`;
    return this.http.get<any>(url)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  /**
   * Api to get traceability details
   */
  getTraceability(orderId: string, productLevel2Id: string, productLevel3Id: string): Observable<any> {

    const params = {
      orderId,
      productLevel2Id,
      productLevel3Id
    }
    let url = `${environment.baseUrl}/v2/pages/trace/new`;
    return this.http.get<any>(url, { params })
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

  /**
   * Api to get fulfillments details
   */
  getFulfillmentDetails(orderId: string, productLevel3Id: string): Observable<any> {

    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    let headers = {
    };

    headers = this.getHeaders(store, orderId)

    let url = `${environment.baseUrl}/v2/estore/FMTPlans/traceability?orderId=${orderId}&productLevel3Id=${productLevel3Id}`;
    return this.http.get<any>(url, headers)
      .pipe(

        map(res => this.util.apiErrorHandler(res)),

        map(res => {
          return res.data;
        })
      );
  }


  /**
   * Api to get banners
   */
  getBannersList(bannerId: string): Observable<any> {

    let url = `${environment.baseUrl}/data/storeProducts/banners/${bannerId}`;
    return this.http.get<any>(url)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  /**
   * Api method to get recommended products
   */
  getRecommendedProducts(): Observable<any> {

    const params = {
      appStoreDomain: environment.bizStoreDomain
    }

    let url = `${environment.restBaseUrlV2}/listing/products/recommendations`;
    return this.http.get<any>(url, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),
        map(res => {
          return res.data;
        })
      );
  }

  getHeaders(store, orderId) {
    let headers: any;
    if (store === 'bizstore') {
      headers = {
        headers: { project: 'OUTPUTS_STORE' }
      }
    } else {
      headers = {
        headers: { project: this.getStore(orderId) }
      }
    }

    return headers;
  }

  getStore(orderId) {
    if (orderId.includes('AGORD')) return 'APGREEN_STORE';
    if (orderId.includes('EMORD')) return 'EMAHILA_STORE';
    if (orderId.includes('TGORD')) return 'TGREENS_STORE';
    if (orderId.includes('SSORD')) return 'SIKKIM_GREENS_STORE';
  }

}

const categories = [
  {
    "id": "SPICES",
    "value": "Spices"
  },
  {
    "id": "HERBAL PRODUCTS",
    "value": "Herbal products",
  },
  {
    "id": "FRUITS",
    "value": "Fruits",
  },
  {
    "id": "FOOD_PRODUCTS",
    "value": "Food Products"
  },
  {
    "id": "OTHERS",
    "value": "Others"
  },
  {
    "id": "PULSES",
    "value": "Pulses",
  },
  {
    "id": "EDIBLE_NUTS_AND_SEEDS",
    "value": "Edible Nuts & Seeds",
  },

];